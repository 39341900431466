import "../App.css";
import Diamond from "../Diamond.svg";
import { Fragment, useEffect } from "react";
import React, { useState } from "react";

const ContactPage = () => {
  return (
    <Fragment>
      <div
        style={{
          marginBottom: "8rem",
        }}
      >
        <div className="page-title-container">
          <div className="page-title">
            <div className="text-bg-container contact">
              <img
                src={Diamond}
                className="text-bg-diamond"
                alt="keymash keyframe diamond"
              />
            </div>
            <h1 className="heading contact">
              GET IN TOUCH<span className="diamond-period">◆</span>
            </h1>
          </div>
        </div>

        <address className="contact-details">
          <ul>
            <li className="h2">
              CALL US
              <br />
              <a className="p2 contact-number" href="tel: +64-27-349-7264">
                +64 27 3497 264
              </a>
            </li>
            <br />

            <br />

            <li className="h2">SEND US AN EMAIL</li>
            <a href="mailto: contact@keymash.design" className="email-add">
              contact@keymash.design
            </a>
          </ul>
        </address>
      </div>
    </Fragment>
  );
};

export default ContactPage;
