import "../App.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="copyright">© KeyMash Design 2021</div>
      <a href="#pageTop" className="back-to-top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34.056"
          height="14.406"
          viewBox="0 0 34.056 14.406"
        >
          <path
            id="Path_5"
            data-name="Path 5"
            d="M942,2133l16.667-11.333L974.333,2133"
            transform="translate(-941.157 -2119.835)"
            fill="none"
            stroke="#3b4756"
            stroke-width="3"
          />
        </svg>
      </a>
      <div className="social-icons">
        <a
          href="https://www.instagram.com/keymash.design"
          target="_blank"
          title="Keymash Instagram page"
        >
          <svg
            className="socialIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              id="Path_3"
              data-name="Path 3"
              d="M8,1.422a24.53,24.53,0,0,1,3.2.089,4.123,4.123,0,0,1,1.511.267,3.122,3.122,0,0,1,1.511,1.511A4.123,4.123,0,0,1,14.489,4.8c0,.8.089,1.067.089,3.2a24.53,24.53,0,0,1-.089,3.2,4.123,4.123,0,0,1-.267,1.511,3.122,3.122,0,0,1-1.511,1.511,4.123,4.123,0,0,1-1.511.267c-.8,0-1.067.089-3.2.089a24.53,24.53,0,0,1-3.2-.089,4.123,4.123,0,0,1-1.511-.267,3.122,3.122,0,0,1-1.511-1.511A4.123,4.123,0,0,1,1.511,11.2c0-.8-.089-1.067-.089-3.2a24.53,24.53,0,0,1,.089-3.2,4.123,4.123,0,0,1,.267-1.511A3.192,3.192,0,0,1,2.4,2.4a1.5,1.5,0,0,1,.889-.622A4.123,4.123,0,0,1,4.8,1.511,24.53,24.53,0,0,1,8,1.422M8,0A26.264,26.264,0,0,0,4.711.089,5.488,5.488,0,0,0,2.756.444a3.48,3.48,0,0,0-1.422.889A3.48,3.48,0,0,0,.444,2.756,4.05,4.05,0,0,0,.089,4.711,26.264,26.264,0,0,0,0,8a26.264,26.264,0,0,0,.089,3.289,5.488,5.488,0,0,0,.356,1.956,3.48,3.48,0,0,0,.889,1.422,3.48,3.48,0,0,0,1.422.889,5.488,5.488,0,0,0,1.956.356A26.264,26.264,0,0,0,8,16a26.264,26.264,0,0,0,3.289-.089,5.488,5.488,0,0,0,1.956-.356,3.729,3.729,0,0,0,2.311-2.311,5.488,5.488,0,0,0,.356-1.956C15.911,10.4,16,10.133,16,8a26.264,26.264,0,0,0-.089-3.289,5.488,5.488,0,0,0-.356-1.956,3.48,3.48,0,0,0-.889-1.422A3.48,3.48,0,0,0,13.244.444,5.488,5.488,0,0,0,11.289.089,26.264,26.264,0,0,0,8,0M8,3.911A4.023,4.023,0,0,0,3.911,8,4.089,4.089,0,1,0,8,3.911m0,6.756A2.619,2.619,0,0,1,5.333,8,2.619,2.619,0,0,1,8,5.333,2.619,2.619,0,0,1,10.667,8,2.619,2.619,0,0,1,8,10.667m4.267-7.911a.978.978,0,1,0,.978.978.986.986,0,0,0-.978-.978"
              fill="#3b4756"
              fill-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://twitter.com/keymashdesign"
          target="_blank"
          title="Keymash Twitter page"
        >
          <svg
            className="socialIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g id="Group_3" data-name="Group 3" transform="translate(0)">
              <rect
                id="Rectangle_393"
                data-name="Rectangle 393"
                width="16"
                height="16"
                fill="none"
              />
              <path
                id="Path_2"
                data-name="Path 2"
                d="M43.067,14.978A9.25,9.25,0,0,0,52.4,5.644V5.2A7.225,7.225,0,0,0,54,3.511a7.379,7.379,0,0,1-1.867.533,3.458,3.458,0,0,0,1.422-1.778,8.153,8.153,0,0,1-2.044.8A3.175,3.175,0,0,0,49.111,2a3.34,3.34,0,0,0-3.289,3.289A1.733,1.733,0,0,0,45.911,6a9.19,9.19,0,0,1-6.756-3.467,3.4,3.4,0,0,0-.444,1.689,3.532,3.532,0,0,0,1.422,2.756,3,3,0,0,1-1.511-.444h0a3.249,3.249,0,0,0,2.667,3.2,2.74,2.74,0,0,1-.889.089,1.513,1.513,0,0,1-.622-.089,3.367,3.367,0,0,0,3.111,2.311A6.711,6.711,0,0,1,38.8,13.467a2.461,2.461,0,0,1-.8-.089,8.39,8.39,0,0,0,5.067,1.6"
                transform="translate(-38 -0.001)"
                fill="#3b4756"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>

        <a
          href="https://www.facebook.com/KayMash-Design-108121501761478"
          target="_blank"
          title="Keymash Facebook page"
        >
          <svg
            className="socialIcon"
            id="Group_4"
            data-name="Group 4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16.001"
            viewBox="0 0 16 16.001"
          >
            <rect
              id="Rectangle_392"
              data-name="Rectangle 392"
              width="16"
              height="16"
              fill="none"
            />
            <path
              id="Path_1"
              data-name="Path 1"
              d="M85.422,16V8.711h2.489l.356-2.844H85.422V4.089c0-.8.267-1.422,1.422-1.422h1.511V.089C88,.089,87.111,0,86.133,0a3.431,3.431,0,0,0-3.644,3.733V5.867H80V8.711h2.489V16Z"
              transform="translate(-76 0.001)"
              fill="#3b4756"
              fill-rule="evenodd"
            />
          </svg>
        </a>

        <a href="mailto: contact@keymash.design">
          <svg
            className="socialIcon mailicon"
            xmlns="http://www.w3.org/2000/svg"
            width="17.453"
            height="13.8"
            viewBox="0 0 17.453 13.8"
          >
            <g
              id="Group_1"
              data-name="Group 1"
              transform="translate(-25.649 -45.951)"
            >
              <g
                id="_3324994_mail_icon"
                data-name="3324994_mail_icon"
                transform="translate(27 46.951)"
              >
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M3.475,4h11.8A1.479,1.479,0,0,1,16.75,5.475v8.85A1.479,1.479,0,0,1,15.275,15.8H3.475A1.479,1.479,0,0,1,2,14.325V5.475A1.479,1.479,0,0,1,3.475,4Z"
                  transform="translate(-2 -4)"
                  fill="none"
                  stroke="#3b4756"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M16.75,6,9.375,9.9,2,6"
                  transform="translate(-2 -4)"
                  fill="none"
                  stroke="#3b4756"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
