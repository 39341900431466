import React, { useState } from "react";
import logo_main from "../Logo Basic.svg";
import { RiMenu3Fill } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import NavLinks from "./NavLinks";
import { Link, useLocation } from "react-router-dom";

const MobileNav = () => {
  const [open, setOpen] = useState(false);

  const hamburgerIcon = (
    <RiMenu3Fill className="menu-open" onClick={() => setOpen(!open)} />
  );

  const closeIcon = (
    <RiCloseFill className="menu-open" onClick={() => setOpen(!open)} />
  );

  const closeMobileMenu = () => setOpen(false);

  let location = useLocation();

  return (
    <div className="nav-mobile-container">
      <div
        className={`logo-small-container ${
          location.pathname === "/" ? "logo-small-home-invisible" : ""
        }`}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#f2d57e",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: "2"
          }}
        >
          <img src={logo_main} className="logo-small-mobile" />
        </Link>
      </div>
      <ul className="mobile-nav">
        {open ? closeIcon : hamburgerIcon}
        {open && (
          <NavLinks
            // pageNumber={pageNumber}
            // setPageNumber={setPageNumber}
            isMobile={true}
            closeMobileMenu={closeMobileMenu}
          />
        )}
      </ul>

      {/* <div className="contact" onClick={openForm}>
                CONTACT
            </div>

      <div
        className={`${isContactModalOn ? "contactModalOn" : "contactModalOff"}`}
      >
        CONTACT
      </div> */}
    </div>
  );
};

export default MobileNav;
