import "../App.css";
import Diamond from "../Diamond.svg";
import { Fragment, useEffect } from "react";
import PageButton from "./PageButton";
import Diamonds from "../Diamonds.svg";
import About from "../data/About.json";

const AboutPage = () => {

  return (
    <Fragment>
      <div className="services-content">
        <div className="page-title-container">
          <div className="page-title">
            <div className="text-bg-container">
              <img src={Diamond} className="text-bg-diamond" alt="keymash keyframe diamond"/>
            </div>
            <h1 className="heading">
              WHO WE ARE<span className="diamond-period">◆</span>
            </h1>
          </div>
        </div>
        <ul className="items">
          {About.map((item) => {
            return (
              <Fragment>
                <li className="page-item">
                  <img
                    src={item.image}
                    className="page-item-img"
                    alt={item.alt}
                  />
                  <div className="page-item-text">
                    <h2 className="h1 about">{item.title}</h2>
                    <h3 className="description">{item.description}</h3>

                    <p className="p1">{item.paragraph}</p>
                  </div>
                </li>

                <div className="diamonds-container">
                  <img src={Diamonds} className="diamonds" />
                </div>
              </Fragment>
            );
          })}
        </ul>
      </div>

      <div className="page-button bottom">
        <PageButton
          // pageNumber={pageNumber}
          // setPageNumber={setPageNumber}
          buttonText="GET IN TOUCH"
          direction="R"
          goTo="/contact"
          title="contact page"
        />
      </div>
    </Fragment>
  );
};

export default AboutPage;
