import React, { useEffect, useState } from "react";
import logo_main from "../Logo Basic.svg";
import { Link, useLocation } from "react-router-dom";

const DesktopNav = () => {
  let location = useLocation();

  return (
    <div className="nav-container">
      <div className={`logo-small-container`}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#f2d57e",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: "2"
          }}
        >
          <img src={logo_main} className="logo-small" />
        </Link>
      </div>
      <ul className="center-nav">
        <li>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "#f2d57e",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              className={`nav-item ${
                location.pathname === "/" ? "intro-active" : ""
              }`}
              title="intro page"
            >
              INTRO
            </a>
          </Link>
          <div
            className={`nav-cursor ${
              location.pathname !== "/" ? "invisible" : ""
            }`}
            style={{ color: "#5F5AFF" }}
          >
            ◆
          </div>
        </li>
        <li>
          <Link
            to="/work"
            style={{
              textDecoration: "none",
              color: "#f2d57e",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              className={`nav-item ${
                location.pathname === "/work" ? "work-active" : ""
              }`}
              title="our work page"
            >
              WORK
            </a>
          </Link>
          <div
            className={`nav-cursor ${
              location.pathname !== "/work" ? "invisible" : ""
            }`}
            style={{ color: "#FF5A5A" }}
          >
            ◆
          </div>
        </li>
        <li>
          <Link
            to="/services"
            style={{
              textDecoration: "none",
              color: "#f2d57e",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              className={`nav-item ${
                location.pathname === "/services" ? "services-active" : ""
              }`}
              title="services page"
            >
              SERVICES
            </a>
          </Link>
          <div
            className={`nav-cursor ${
              location.pathname !== "/services" ? "invisible" : ""
            }`}
            style={{ color: "#23C273" }}
          >
            ◆
          </div>
        </li>
        <li>
          <Link
            to="/about"
            style={{
              textDecoration: "none",
              color: "#f2d57e",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              className={`nav-item ${
                location.pathname === "/about" ? "about-active" : ""
              }`}
              title="about us page"
            >
              ABOUT
            </a>
          </Link>
          <div
            className={`nav-cursor ${
              location.pathname !== "/about" ? "invisible" : ""
            }`}
            style={{ color: "#BD4AFF" }}
          >
            ◆
          </div>
        </li>
      </ul>
      <Link
        to="/contact"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          className={`contact ${
            location.pathname === "/contact" ? "contact-active" : ""
          }`}
          title="contact page"
        >
          CONTACT
        </a>
      </Link>
    </div>
  );
};

export default DesktopNav;
