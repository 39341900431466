import "../App.css";
import Diamond from "../Diamond.svg";
import { Fragment, useEffect } from "react";
import PageButton from "./PageButton";
import Diamonds from "../Diamonds.svg";
import Services from "../data/Services.json";

const ServicesPage = () => {

  return (
    <Fragment>
      <div className="services-content">
        <div className="page-title-container">
          <div className="page-title">
            <div className="text-bg-container">
              <img src={Diamond} className="text-bg-diamond" alt="keymash keyframe diamond"/>
            </div>
            <h1 className="heading">
              WHAT WE DO<span className="diamond-period">◆</span>
            </h1>
          </div>
        </div>
        <ul className="items">
          {Services.map((item) => {
            return (
              <Fragment>
                <li className="page-item">
                  <img
                    src={item.image}
                    className="page-item-img"
                    alt={item.alt}
                  />
                  <div className="page-item-text">
                    <h2 className="h1">{item.title}</h2>

                    <p className="p1">{item.paragraph}</p>
                  </div>
                </li>

                <div className="diamonds-container">
                  <img src={Diamonds} className="diamonds" />
                </div>
              </Fragment>
            );
          })}
        </ul>
      </div>

      <div className="page-button bottom">
        <PageButton
          // pageNumber={pageNumber}
          // setPageNumber={setPageNumber}
          buttonText="OUR WORK"
          direction="L"
          goTo="/work"
          title="our work page"
        />
      </div>
    </Fragment>
  );
};

export default ServicesPage;
