import React, { useState } from "react";
import { Fragment } from "react";
import logo_main from "../Logo Basic.svg";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

function ContentView() {
  {
    let element = document.getElementsByClassName("nav-intro");
    element.classList.add("intro-active");
  }
}

const NavBar = () => {
  return (
    <Fragment>
      <nav className="desktop-only">
        <DesktopNav />
      </nav>
      <nav className="mobile-only">
        <MobileNav />
      </nav>
    </Fragment>
  );
};

export default NavBar;
