import React, { useState } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Link } from "react-router-dom";

const NavLinks = ({ closeMobileMenu }) => {
  // const animateFrom = { opacity: 0, y: -40 };
  // const animateTo = { opacity: 1, y: 0 };

  return (
    <Fragment>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <li className="mobile-nav-li">
          <div
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-nav-item"
          >
            INTRO
          </div>
        </li>
      </Link>
      <Link
        to="/work"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <li className="mobile-nav-li">
          <div
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-nav-item"
          >
            WORK
          </div>
        </li>
      </Link>
      <Link
        to="/services"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <li className="mobile-nav-li">
          <div
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-nav-item"
          >
            SERVICES
          </div>
        </li>
      </Link>
      <Link
        to="/about"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <li className="mobile-nav-li">
          <div
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-nav-item"
          >
            ABOUT
          </div>
        </li>
      </Link>
      <Link
        to="/contact"
        style={{
          textDecoration: "none",
          color: "#f2d57e",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <li className="mobile-nav-li">
          <div
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-nav-item"
          >
            CONTACT
          </div>
        </li>
      </Link>
    </Fragment>
  );
};

export default NavLinks;
