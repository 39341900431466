import { Fragment, useState, useEffect } from "react";
import logo_main from "../Logo Final.svg";
import PageButton from "./PageButton";

const IntroPage = () => {

  return (
    <Fragment>
      <div className="intro-page-content">
        <div className="main-logo-container">
          <h1 className="hidden">Keymash Design</h1>
          <img src={logo_main} className="main-logo" alt="Keymash Design logo"/>
        </div>
        <h2 className="hidden">Services</h2>
        <ul className="intro-headings">
          <li className="intro-heading-item">MOTION DESIGN</li>
          <div className="text-diamond">◆</div>
          <li className="intro-heading-item">PHOTO & VIDEO</li>
          <div className="text-diamond">◆</div>
          <li className="intro-heading-item">DESIGN & WEB</li>
        </ul>

        <div>
          <p className="p1 intro-p">
            KeyMash is a digital content and design powerhouse. We are a collective based in New Zealand working globally, a highly versatile and efficient option for clients of
            all sizes.
          </p>
        </div>
      </div>
      <div className="page-button bottom">
        <PageButton
          // pageNumber={pageNumber}
          // setPageNumber={setPageNumber}
          buttonText="OUR WORK"
          direction="R"
          goTo="/work"
          title="our work page"
        />
      </div>
    </Fragment>
  );
};

export default IntroPage;
