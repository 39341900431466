import "../App.css";
import Diamond from "../Diamond.svg";
import { Fragment, useEffect } from "react";
import PageButton from "./PageButton";
import videos from "../data/Videos.json";
import work from "../data/Work.json";
import expertise from "../data/Expertise.json";
import Diamonds from "../Diamonds.svg";

const WorkPage = () => {
  return (
    <Fragment>
      <div className="page-title-container">
        <div className="page-title">
          <div className="text-bg-container">
            <img
              src={Diamond}
              className="text-bg-diamond"
              alt="keymash keyframe diamond"
            />
          </div>
          <h1 className="heading">
            OUR WORK<span className="diamond-period">◆</span>
          </h1>
        </div>
      </div>
      <div className="logos-grid">
        {work.map((item) => {
          return (
            <a href={item.link}>
              <img
                alt={item.logoalt}
                src={item.logo}
                className="logo-grid-logo"
              />
            </a>
          );
        })}
      </div>
      <ul className="items">
        {work.map((item) => {
          return (
            <Fragment>
              <li className="page-item">
                <a href={item.link}>
                  <img
                    src={item.image}
                    className="page-item-img"
                    alt={item.imagealt}
                  />
                </a>
                <div className="page-item-text">
                  <h2 className="h1">{item.title}</h2>

                  <p className="p1">{item.description}</p>
                </div>
              </li>

              <div className="diamonds-container">
                <img src={Diamonds} className="diamonds" />
              </div>
            </Fragment>
          );
        })}
      </ul>
      <h1 className="sub-heading">EXPERTISE</h1>
      <div className="expertise-grid">
        {expertise.map((item) => {
          return <span className="expertise">{item}</span>;
        })}
      </div>
      <div className="diamonds-container">
        <img src={Diamonds} className="diamonds" />
      </div>
      <h1 className="sub-heading">REELS</h1>
      <ul className="work-container">
        {videos.map((item) => {
          return (
            <Fragment>
              <li className="video-container">
                <div>
                  <h2 className="h1">{item.title}</h2>
                </div>
                <iframe
                  className="videosmall"
                  src={item.video}
                  alt={item.title}
                ></iframe>

                {/* <hr className="work-hr" /> */}
              </li>
            </Fragment>
          );
        })}
      </ul>

      <div className="page-button bottom">
        <PageButton
          // pageNumber={pageNumber}
          // setPageNumber={setPageNumber}
          buttonText="GET IN TOUCH"
          direction="R"
          goTo="/contact"
          title="contact page"
        />
      </div>
    </Fragment>
  );
};

export default WorkPage;
