import React, { useState } from "react";
import "./App.css";
import WorkPage from "./components/WorkPage";
import NavBar from "./components/NavBar";
import IntroPage from "./components/IntroPage";
import Footer from "./components/Footer";
import ServicesPage from "./components/ServicesPage";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/ContactPage";
import "./Contact.css";
import { Routes, Route, useNavigate } from "react-router-dom";

function App() {
  // const [pageNumber, setPageNumber] = useState(1);
  // console.log("pageNumber ", pageNumber);

  // let pageContent = (
  //   <IntroPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  // );

  // if (pageNumber === 1) {
  //   pageContent = (
  //     <IntroPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  //   );
  // }

  // if (pageNumber === 2) {
  //   pageContent = (
  //     <WorkPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  //   );
  // }

  // if (pageNumber === 3) {
  //   pageContent = (
  //     <ServicesPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  //   );
  // }

  // if (pageNumber === 4) {
  //   pageContent = (
  //     <AboutPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  //   );
  // }

  // if (pageNumber === 5) {
  //   pageContent = (
  //     <ContactPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
  //   );
  // }

  return (
    <div className="main-container" id="pageTop">
      <NavBar />
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
