import React, { useState } from "react";
import { Fragment } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const PageButtonR = ({
  buttonText,
  direction,
  goTo,
  title,
}) => {

  let navigate = useNavigate()

  if (direction === "L") {
    return (
      <button className="button-container" onClick={() => navigate(goTo)} title={title}>
        <IoMdArrowDropleft className="button-direction L" />
        <div className="button-text">{buttonText}</div>
      </button>
    );
  }
  return (
    <button className="button-container" onClick={() => navigate(goTo)} title={title}>
      <div className="button-text">{buttonText}</div>
      <IoMdArrowDropright className="button-direction" />
    </button>
  );
};

export default PageButtonR;
